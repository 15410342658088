import React from "react";
import styled from "styled-components";

interface UeberUnsAkkordeonProps {}

const UeberUnsAkkordeonDiv = styled.div`
  background: #fff;
  padding-top: 12.5vw;
`;

const UeberUnsAkkordeon: React.FC<UeberUnsAkkordeonProps> = ({ children }) => (
  <UeberUnsAkkordeonDiv>{children}</UeberUnsAkkordeonDiv>
);

export default UeberUnsAkkordeon;
