import React from "react";
import styled from "styled-components";
import KontaktDatenSmall from "./KontaktDatenSmall";
import HalfGrid from "./Layout/Grids/HalfGrid";
import Wrapper from "./Layout/Wrapper";

interface KontaktProps {
  inhalt: [
    {
      smallAddressText: string;
      textLinks: string;
      textRechts: string;
      image: [
        {
          url: string;
          title: string;
        }
      ];
    }
  ];
}

const KontaktDiv = styled.div``;

const Kontakt: React.FC<KontaktProps> = ({ inhalt }) => (
  <KontaktDiv>
    <Wrapper>
      <HalfGrid noSpacing>
        {inhalt.length > 0 ? (
          <>
            <div>
              <KontaktDatenSmall
                inhalt={
                  inhalt.filter((obj) => obj.smallAddressText != undefined)[0]
                }
              />
            </div>
            <div>
              <KontaktDatenSmall
                inhalt={
                  inhalt.filter((obj) => obj.smallAddressText != undefined)[1]
                }
              />
            </div>
            <div>
              <KontaktDatenSmall
                inhalt={
                  inhalt.filter((obj) => obj.smallAddressText != undefined)[2]
                }
              />
            </div>
            {/* <KontaktDatenBig
              inhalt={inhalt.filter((obj) => obj.textLinks != undefined)[0]}
            /> */}
          </>
        ) : (
          ""
        )}
      </HalfGrid>
    </Wrapper>
  </KontaktDiv>
);

export default Kontakt;
