import React, { useState } from "react";
import styled from "styled-components";
import AkkordeonContent from "./AkkordeonContent";
import AkkordeonHeader from "../AkkordeonHeader";

interface AkkordeonItemProps {
  white: boolean;
  title: string;
}

const ItemDiv = styled.div``;

const AkkordeonItem: React.FC<AkkordeonItemProps> = ({
  white,
  title,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(white);

  return (
    <ItemDiv>
      <AkkordeonHeader
        title={title}
        white={white}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <AkkordeonContent isOpen={isOpen}>{children}</AkkordeonContent>
    </ItemDiv>
  );
};

export default AkkordeonItem;
