import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../../styles/variables";

interface AkkordeonContentProps {
  isOpen: boolean;
}

const ContentDiv = styled.article`
  width: 100%;
  background: white;
  overflow: hidden;
  height: auto;
  max-height: 0px;
  transition: max-height 300ms;

  &.isOpen {
    max-height: 6000px;
    margin-bottom: 12.5vw;
  }

  @media screen AND (min-width: ${breakpoints.tablet}) {
    &.isOpen {
      max-height: 1500px;
    }
  }
`;

const AkkordeonContent: React.FC<AkkordeonContentProps> = ({
  isOpen,
  children,
}) => {
  return <ContentDiv className={isOpen ? "isOpen" : ""}>{children}</ContentDiv>;
};

export default AkkordeonContent;
