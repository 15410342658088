import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";
import Imgix from "react-imgix";
import { imgix } from "../data/data";

interface PartnerItemProps {
  logo: {
    url: string;
    title: string;
  };
  title: string;
  link: string;
}

const Item = styled.a`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;

  > img {
    display: block;
    max-width: 200px;
    margin-bottom: 12.5px;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > img {
      margin-bottom: 28.5px;
    }
  }
  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    > img {
      margin-bottom: 32.5px;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    > img {
      margin-bottom: 40px;
    }
  }
`;

const PartnerItem: React.FC<PartnerItemProps> = ({ logo, link, title }) => (
  <Item href={link} target="_blank">
    <Imgix
      src={logo}
      imgixProps={imgix.gridLogoImg}
      htmlAttributes={{ alt: title }}
    />
    {/* <h6
      dangerouslySetInnerHTML={{ __html: superScriptRHelperHTML(title) }}
    ></h6> */}
  </Item>
);

export default PartnerItem;
