import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../../styles/variables";

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  /* grid-column-gap: 27px;
  grid-row-gap: 33px; */

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 30px;
    &.noSpacing {
      grid-column-gap: 0;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    grid-column-gap: 40px;
  }
`;

const HalfGrid: React.FC = ({ children, noSpacing }) => (
  <GridDiv className={noSpacing ? "noSpacing" : ""}>{children}</GridDiv>
);

export default HalfGrid;
